import DtoConverter from 'scripts/application/dto_converters/snippet_converter';
import qconsole from 'scripts/lib/qconsole';
import SelectAnswer from 'actions/answers/select_answer';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';

export default class LoadAndSelectAnswer {
  constructor(context) {
    this.context = context;
  }

  run({ answerId, answerLanguage, answerType }) {
    this.context.gateways.snippet.fetch(
      { ids: [answerId] },
      {
        onSuccess: snippetDtos => {
          if (!snippetDtos.length) {
            this.context.executeAction(ShowToast, {
              type: ToastType.WARN,
              message: 'Hmmm, it looks like that answer was deleted',
            });
            return;
          }

          const answerPanel = this.context.stores.answerPanel.get();
          if (!answerPanel.isOpen()) {
            answerPanel.open();
            this.context.stores.answerPanel.set(answerPanel);
          }
          const answer = DtoConverter.fromDto(snippetDtos[0]);
          this.context.executeAction(SelectAnswer, { answer, answerLanguage, answerType, clearHistory: true });
        },

        onError: errorDto => {
          qconsole.log('Failed to fetch requested answer', errorDto);
          this.context.executeAction(ShowToast, {
            type: ToastType.ERROR,
            message: 'Could not load the requested answer',
          });
        },
      }
    );
  }
}
