import { EventEmitter } from 'events';

export default class LocalPubsubClient {
  constructor(pubsub) {
    this._pubsub = pubsub;
    this._emitter = new EventEmitter().setMaxListeners(0);
    this._subscribers = [];
    this._setupDelegates();
  }

  _setupDelegates() {
    ['publish', 'publishAndRetain', 'unsubscribe'].forEach(m => (this[m] = this._pubsub[m].bind(this._pubsub)));
  }

  subscribe(topicPattern, callback, ack) {
    this._subscribers.push({ topicPattern, callback });
    this._pubsub.subscribe(topicPattern, callback, ack);
  }

  on(event, listener) {
    this._emitter.on(event, listener);
  }

  removeListener(event, listener) {
    this._emitter.removeListener(event, listener);
  }

  connect() {}

  configureMqttServerV2(isMqttServerV2) {}

  multiplyDefaultMqttKeepAlive(multiplier) {}

  reset() {
    this._subscribers.forEach(s => this.unsubscribe(s.topicPattern, s.callback));
    this._subscribers = [];
    this._emitter.emit('reset');
  }
}
