import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ErrorBoundary from 'components/common/utilities/error_boundary';
import { ReactCardRenderer } from '@gladly/card-renderer';
import BubbleContent from '../content/bubble_content';
import RedactedItem from 'components/customer/conversation_history/conversation_items_v2/redacted_item';
import ConversationItem from 'models/conversation_item';

const hostAppConfig = {
  defaults: {
    card: {
      background: 'transparent',
      padding: 'none',
      borderRadius: 'none',
      borderWidth: 'none',
      elevation: 'none',
    },
  },
};

export default function ChatTemplatedCardContent({ className, item, status }) {
  return (
    <BubbleContent
      className={classnames('chatItem-content flexible-card', className)}
      data-aid={`chatItem-content-${item.id}`}
      item={item}
      status={status}
    >
      {renderBody(item)}
    </BubbleContent>
  );
}

function renderBody(item) {
  if (item.content.isRedacted) {
    return <RedactedItem />;
  }

  const messageData = item.content.messageData;
  return (
    <ErrorBoundary
      errorMeta={{ errorBoundary: 'Templated Card' }}
      renderError={() => <ChatTemplatedCardError>Unsupported template structure</ChatTemplatedCardError>}
    >
      <ReactCardRendererContainer>
        <ReactCardRenderer data={messageData.data} hostConfig={hostAppConfig} template={messageData.template} />
      </ReactCardRendererContainer>
    </ErrorBoundary>
  );
}

ChatTemplatedCardContent.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  status: PropTypes.string,
};

const ReactCardRendererContainer = styled.div`
  min-width: 250px;
  max-width: 300px;
`;

const ChatTemplatedCardError = styled.div`
  color: red;
  font-size: 12px;
  text-align: center;
`;
