import React from 'react';
import PropTypes from 'prop-types';

import { ChatMessageStatusCode as ChatMessageStatus, ChatMessageType } from 'models/chat_message';
import ChatMetadataIcon from './chat_metadata_icon';
import ConversationItem from 'models/conversation_item';
import ItemCommError from '../item_comm_error';
import ItemContent from '../item_content';
import ItemMetadata from '../item_metadata';
import MobileChatMetadataIcon from './mobilechat_metadata_icon';
import { StyledIconAPI } from '../metadata/api_metadata';
import Communicator from 'models/communicator';

import ChatTextContent from './chat_text_content';
import ChatQuickReplyResponseContent from './chat_quick_reply_response_content';
import ChatQuickReplyRequestContent from './chat_quick_reply_request_content';
import ChatHTMLContent from './chat_html_content';
import ChatTemplatedCardContent from './chat_flexible_card_content';
import ShieldOfTrust from './shield_of_trust';

export default function ChatItem({ className, isFirstInStack, isLastInStack, isStacked, item }) {
  let status = getStatus();
  return (
    <ItemContent
      isFirstInStack={isFirstInStack}
      isLastInStack={isLastInStack}
      isStacked={isStacked}
      item={item}
      showIcon={!isStacked}
    >
      <span>
        <ItemMetadata
          icon={getIcon(item)}
          item={item}
          showMetadata={!isStacked}
          status={status}
          text={getText(item.content)}
          tooltip={getTooltip(item.content)}
        />
        {showShieldOfTrust(item, isStacked) && <ShieldOfTrust conversationId={item.conversationId} />}
      </span>
      <ChatContent className={className} item={item} status={status} />
      <ItemCommError item={item} />
    </ItemContent>
  );

  function showShieldOfTrust(item, isStacked) {
    return !isStacked && item.content.isAuthenticated && item.isCustomerItem();
  }

  function getIcon(item) {
    if (item.initiator.type === Communicator.API) {
      if (item.content.isMobileSdk()) {
        return (
          <span>
            <StyledIconAPI className="api-icon">APP</StyledIconAPI>
            <MobileChatMetadataIcon />
          </span>
        );
      }
      return (
        <span>
          <StyledIconAPI className="api-icon">APP</StyledIconAPI>
          <ChatMetadataIcon />
        </span>
      );
    }

    if (item.content.isMobileSdk()) {
      return <MobileChatMetadataIcon />;
    }
    return <ChatMetadataIcon />;
  }

  function getText(content) {
    switch (content.messageType) {
      case ChatMessageType.QUICK_REPLY_REQUEST:
        return addMobileSuffix(content, 'sent a Quick Reply request');
      case ChatMessageType.QUICK_REPLY_RESPONSE:
        return addMobileSuffix(content, 'sent a Quick Reply via chat');

      default:
        return addMobileSuffix(content, 'sent a chat');
    }
  }

  function addMobileSuffix(content, text) {
    if (text && content.isMobileSdk()) {
      return `${text} from App`;
    }
    return text;
  }

  function getTooltip(content) {
    if (content.isMobileSdk()) {
      let os = [content.os, content.osVersion].filter(item => !!item).join(' ');
      let sdk = content.sdkVersion ? ['SDK', content.sdkVersion].filter(item => !!item).join(' ') : undefined;
      return [content.deviceManufacturer, content.deviceModel, os, sdk].filter(item => !!item).join(', ');
    }
    return null;
  }

  function getStatus() {
    const status = item.content.getStatus();
    switch (status) {
      case ChatMessageStatus.SENT:
        return 'delivered';
      case ChatMessageStatus.FAILED:
        return 'delivery failed';
      case ChatMessageStatus.NEW:
        return 'sending';
      default:
        return '';
    }
  }
}

ChatItem.propTypes = {
  className: PropTypes.string,
  isFirstInStack: PropTypes.bool,
  isLastInStack: PropTypes.bool,
  isStacked: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

export function ChatContent({ className, item, status }) {
  switch (item.content.messageType) {
    case ChatMessageType.QUICK_REPLY_REQUEST:
      return (
        <ChatQuickReplyRequestContent
          className={className}
          item={item}
          options={item.content.messageData.options}
          requestText={item.content.text}
          status={status}
        />
      );
    case ChatMessageType.QUICK_REPLY_RESPONSE:
      return <ChatQuickReplyResponseContent className={className} item={item} status={status} />;
    case ChatMessageType.HTML:
      return <ChatHTMLContent className={className} item={item} status={status} />;
    case ChatMessageType.FLEXIBLE_CARD:
      return <ChatTemplatedCardContent className={className} item={item} status={status} />;
    default:
      return <ChatTextContent className={className} item={item} status={status} />;
  }
}

ChatContent.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  status: PropTypes.string,
};
