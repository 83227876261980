import _ from 'lodash';
import Cookies from 'js-cookie';
import { createLocalRestBackend } from './backends/local_rest_backend';
import DatasetGateway from './backends/dataset_gateway';
import * as databases from './backends/fake_backend/database';
import CommunicationSimulationService from './backends/communication_simulation_service';
import FakeBackend from './backends/fake_backend';
import FakeS3 from './backends/fake_s3';
import FakeTwilio from './backends/fake_twilio';
import LocalPubsubBackend from './backends/local_pubsub_backend';
import LocalPubsubClient from './backends/local_pubsub_client';
import qconsole from 'scripts/lib/qconsole';
import * as Sentry from '@sentry/browser';

export default class Backends {
  constructor({
    backendProxy,
    pubsubClient,
    restClient,
    gateways,
    mqttEventHandlers,
    hostname,
    twilioProxy,
    commSimulationProxy,
    eventRecorder,
    requestorId,
  }) {
    this.proxy = backendProxy;
    this._pubsubClient = pubsubClient;
    this._restClient = restClient;
    this.gateways = gateways;
    this.mqttEventHandlers = mqttEventHandlers || [];
    this.hostname = hostname || window.location.hostname;
    this.twilioProxy = twilioProxy;
    this.commSimulationProxy = commSimulationProxy;
    this.eventRecorder = eventRecorder;
    this.requestorId = requestorId;
  }

  destroy() {
    this.disableDemoMode();
    this.proxy.close();
    this._fakeBackend = null;
    this._localPubsub = null;
    this._localRest = null;
  }

  initBackend() {
    let backend = this.hostname.startsWith('demo.localhost') ? 'local' : 'remote';
    this._useBackend(Cookies.get('backend') || backend);
  }

  switchBackend(backendName) {
    this.destroy();
    this._useBackend(backendName);
  }

  _setBackendSentry(backend) {
    // https://docs.sentry.io/enriching-error-data/context/?platform=javascript#tagging-events
    Sentry.configureScope(function(scope) {
      scope.setTag('backend', backend);
    });
  }

  _useBackend(backendName) {
    if (backendName === 'remote') {
      this._useRemote();
    } else if (backendName === 'local') {
      this._useLocal();
    } else {
      qconsole.warn(`Unknown backend: ${backendName}`);
      this._useLocal();
    }
    this._setBackendSentry(backendName);
    this._registerGatewayHandlers();
  }

  get inProgress() {
    let localPubsubIsInProgress = this._localPubsub && this._localPubsub.inProgress;
    let localRestIsInProgress = this._localRest && this._localRest.inProgress;
    return localPubsubIsInProgress || localRestIsInProgress;
  }

  _useRemote() {
    this._switchTwilioImpl(global.Twilio);
    this.mqttEventHandlers.forEach(h => this._pubsubClient.on(h.event, h.handler));
    this._switchImpl([this._pubsubClient, this._restClient]);
    this.commSimulationProxy.impl = null;

    Cookies.set('backend', 'remote', { expires: 365 });
    qconsole.log('Using backend: remote');
  }

  _useLocal() {
    let fakeS3 = new FakeS3();

    this._localPubsub = new LocalPubsubBackend();
    this._fakeBackend = new FakeBackend(this._localPubsub, databases.getDatabase, fakeS3);
    this._fakeBackend.activate();

    let incomingCallService = this._fakeBackend.incomingCallService;
    let fakeTwilio = new FakeTwilio();
    fakeTwilio.onClientAccept = incomingCallService.acceptCallForCurrentAgent.bind(incomingCallService);
    fakeTwilio.onClientDisconnect = incomingCallService.endCallForCurrentAgent.bind(incomingCallService);
    this._switchTwilioImpl(fakeTwilio);

    this._localRest = createLocalRestBackend(
      DatasetGateway.instance,
      fakeS3,
      this._localPubsub,
      databases.getDatabase,
      incomingCallService
    );

    this._switchImpl([new LocalPubsubClient(this._localPubsub), this._localPubsub, this._localRest]);

    this.commSimulationProxy.impl = new CommunicationSimulationService(this.proxy, fakeTwilio, {
      conversations: this._fakeBackend.conversationsService,
      incomingCall: this._fakeBackend.incomingCallService,
    });

    Cookies.set('backend', 'local', { expires: 365 });
    qconsole.log('Using backend: local');
  }

  _switchImpl(newImpls) {
    this.proxy.impls = newImpls;
  }

  _switchTwilioImpl(twilioImpl) {
    this.twilioProxy.impl = twilioImpl;
  }

  _registerGatewayHandlers() {
    _.values(this.gateways).forEach(gateway => {
      if (gateway.reconnectHandler) {
        this.proxy.on('reconnect', gateway.reconnectHandler.bind(gateway));
      }
      if (gateway.resetHandler) {
        this.proxy.on('reset', gateway.resetHandler.bind(gateway));
      }
    });
  }

  onReconnect(cb) {
    this.proxy.on('reconnect', cb);
  }

  reset() {
    this.proxy.reset();
  }

  enableDemoMode() {
    qconsole.log('turning demo mode ON');
    this._pubsubClient.enableDemo();
    this._restClient.enableDemo();
  }

  disableDemoMode() {
    qconsole.log('turning demo mode OFF');
    this._pubsubClient.disableDemo();
    this._restClient.disableDemo();
  }

  startRecordingEvents() {
    this.eventRecorder.startRecordingEvents();
  }

  stopRecordingEvents() {
    return this.eventRecorder.stopRecordingEvents();
  }
}

export class BackendProxy {
  get impls() {
    if (!this._impls) {
      throw new Error('BackendProxy not initialized. Call initBackend() on the Backends first.');
    }
    return this._impls;
  }

  set impls(impls) {
    this._impls = impls;
  }

  // close is a special case since each implementation may have a close method that needs to be called
  close() {
    // close method takes no arguments since varying arguments between implementation will be difficult to rationalized
    _.forEach(this.impls, impl => impl.close && impl.close());
  }
}

[
  'connect',
  'reset',
  'publish',
  'subscribe',
  'unsubscribe',
  'on',
  'removeListener',
  'post',
  'postUpload',
  'get',
  'impersonate',
  'delete',
  'put',
  'axios',
  'multiplyDefaultMqttKeepAlive',
  'configureMqttServerV2',
].forEach(methodName => {
  BackendProxy.prototype[methodName] = function() {
    // find the first matching method in one of the implementations
    for (let i = 0; i < this.impls.length; i++) {
      if (this.impls[i][methodName]) {
        return this.impls[i][methodName](...arguments);
      }
    }

    throw new Error(`BackendProxy does not have an implementation with method ${methodName}`);
  };
});
