export default class LoadProfileSnippets {
  constructor(context) {
    this.context = context;
  }

  run(agentProfile = {}) {
    const { emailGreetingSnippetId, emailSignatureSnippetId } = agentProfile;
    let snippetIds = [];

    if (emailGreetingSnippetId) {
      snippetIds.push(emailGreetingSnippetId);
    }

    if (emailSignatureSnippetId) {
      snippetIds.push(emailSignatureSnippetId);
    }

    if (snippetIds.length > 0) {
      this.context.gateways.snippet.fetch({ ids: snippetIds });
    }
  }
}
