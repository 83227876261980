import _ from 'lodash';

import DtoConverter from 'scripts/application/dto_converters/snippet_converter';
import { getAllLinkedAnswerIds } from 'models/answers/snippet_helpers';
import HandleRequestedAnswers from 'actions/answers/handle_requested_answers';
import qconsole from 'scripts/lib/qconsole';
import SetAnswerView from 'actions/knowledge_base/set_answer_view';

export default class SnippetLinksGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get snippetViewStore() {
    return this.context.stores.snippetView;
  }

  get snippetLinksStore() {
    return this.context.stores.snippetLinks;
  }

  handleRequestedEntities(snippetDtos) {
    const currentLocation = this.context.stores.currentLocation.get();
    if (currentLocation.customerId) {
      let answers = snippetDtos.map(dto => DtoConverter.fromDto(dto));
      this.context.executeAction(HandleRequestedAnswers, { answers });
      return;
    }

    let snippetViewStore = this.snippetViewStore.get();
    let answerPanelState = this.context.stores.answerPanelState.get();
    let answerPanelStateHistory = answerPanelState && answerPanelState.history;
    let snippetCompositionStore = this.context.stores.snippetComposition.get();

    let snippetCompositionLinkedAnswerIds = getAllLinkedAnswerIds(snippetCompositionStore);
    let snippetViewLinkedAnswerIds = getAllLinkedAnswerIds(snippetViewStore);
    let linkedAnswerIds = _.compact(
      _.concat(snippetViewLinkedAnswerIds, snippetCompositionLinkedAnswerIds, answerPanelStateHistory)
    );

    let snippets = snippetDtos.map(dto => DtoConverter.fromDto(dto));
    // if there are no snippet links we want to reset loading
    let shouldResetSnippetLinksLoading = snippets.length === 0;
    _.forEach(snippets, snippet => {
      if (_.includes(linkedAnswerIds, snippet.id)) {
        this.snippetLinksStore.addOrReplace(snippet);
        if (!shouldResetSnippetLinksLoading) {
          shouldResetSnippetLinksLoading = true;
        }
      }
    });

    if (shouldResetSnippetLinksLoading) {
      this.context.stores.snippetLinks.resetLoading();
    }

    if (
      _.get(answerPanelState, 'directLink') &&
      _.get(snippetViewStore, 'id') !== answerPanelState.directLink.id &&
      _.some(snippets, s => s.id === answerPanelState.directLink.id)
    ) {
      let snippet = this.context.stores.snippetLinks.findBy({ id: answerPanelState.directLink.id });
      this.context.executeAction(SetAnswerView, { snippet });
    }
  }

  handleRequestError(errorDto) {
    qconsole.log('Failed to fetch snippets', errorDto);
  }
}
