import _ from 'lodash';

import DtoConverter from 'scripts/application/dto_converters/snippet_converter';
import qconsole from 'scripts/lib/qconsole';

export default class ProfileSnippetGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get currentAgentStore() {
    return this.context.stores.currentAgent;
  }

  get store() {
    return this.context.stores.currentAgentSnippets;
  }

  handleRequestedEntities(snippetDtos) {
    let currentAgent = this.currentAgentStore.get();
    if (!currentAgent || snippetDtos.length > 2) {
      return;
    }

    const { emailGreetingSnippetId, emailSignatureSnippetId } = currentAgent;
    if (emailGreetingSnippetId || emailSignatureSnippetId) {
      const snippets = snippetDtos.map(dto => DtoConverter.fromDto(dto));

      _.forEach(snippets, snippet => {
        if (snippet.id === emailGreetingSnippetId) {
          this.store.addOrReplace(snippet);
        }

        if (snippet.id === emailSignatureSnippetId) {
          this.store.addOrReplace(snippet);
        }
      });
    }
  }

  handleEntity(snippetDto) {
    let updatedSnippet = DtoConverter.fromDto(snippetDto);
    const hasSnippet = this.store.has({ id: updatedSnippet.id });
    if (hasSnippet) {
      this.store.addOrReplace(updatedSnippet);
    }
  }

  handleRequestError(errorDto) {
    qconsole.log('Failed to fetch snippets', errorDto);
  }
}
