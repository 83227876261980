import DtoConverter from 'scripts/application/dto_converters/snippet_converter';
import ErrorDtoConverter from 'scripts/application/dto_converters/err_converter';
import KbAdmin from 'models/location/kb_admin';
import NavigateToKbAdmin from 'actions/knowledge_base/navigate_to_kb_admin';
import qconsole from 'scripts/lib/qconsole';
import ShowAdminNotification from 'actions/notification/show_admin_notification';
import ShowSnippetSuccessToast from './show_snippet_success_toast';

export default class SnippetGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.snippets;
  }

  get compositionStore() {
    return this.context.stores.snippetComposition;
  }

  get snippetComposition() {
    return this.compositionStore.get();
  }

  handleRequestError(errorDto) {
    qconsole.log('Failed to fetch snippets', errorDto);
  }

  handleEntity(snippetDto) {
    let snippet = DtoConverter.fromDto(snippetDto);
    let isCompositionPending = this.compositionStore.isPending();

    if (snippet.id === this.currentSnippetId && !isCompositionPending) {
      this.context.executeAction(
        ShowAdminNotification,
        'Another user has edited this answer. Saving will overwrite their changes'
      );
    }

    this.store.addOrReplace(snippet);
  }

  handleDelete(snippetId) {
    this.store.remove(snippetId);

    if (snippetId === this.currentSnippetId) {
      this.compositionStore.remove();
      this.context.executeAction(NavigateToKbAdmin);
      this.context.executeAction(ShowAdminNotification, 'Another user deleted that answer');
    }
  }

  // Save result handlers

  hasPendingAdd() {
    return (
      this.snippetComposition &&
      this.compositionStore.isPending() &&
      !this.store.findBy({ id: this.snippetComposition.id })
    );
  }

  hasPendingUpdate(snippetId) {
    return this.compositionStore.isPending() && this.compositionStore.getPending().id === snippetId;
  }

  handleAddErrors(errorsDto) {
    if (this.hasPendingAdd()) {
      this.compositionStore.setErrors(errorsDto.errors.map(ErrorDtoConverter.fromDto));
      this.compositionStore.resetPending();
    }
  }

  handleAddSuccess() {
    if (this.snippetComposition && this.compositionStore.isPending()) {
      let snippet = this.compositionStore.getPending();
      this.context.executeAction(ShowSnippetSuccessToast, snippet);

      this.compositionStore.remove();
      this.context.router.navigateTo(KbAdmin.create());
    }
  }

  handleUpdateErrors(snippetId, errorsDto) {
    if (this.hasPendingUpdate(snippetId)) {
      this.compositionStore.setErrors(errorsDto.errors.map(ErrorDtoConverter.fromDto));
      this.compositionStore.resetPending();
    }
  }

  handleUpdateSuccess(snippetId) {
    if (this.hasPendingUpdate(snippetId)) {
      let snippet = this.compositionStore.getPending();
      this.context.executeAction(ShowSnippetSuccessToast, snippet);

      this.compositionStore.remove();
      this.context.stores.snippets.commitPending(snippetId);
      this.context.router.navigateTo(KbAdmin.create());
    }
  }

  handleDeleteErrors(snippetId, errorsDto) {
    if (this.store.findBy({ id: snippetId })) {
      this.store.resetPending(snippetId);
      this.store.setErrors(snippetId, errorsDto.errors.map(ErrorDtoConverter.fromDto));
    }
  }

  handleDeleteSuccess(snippetId) {
    this.context.stores.snippets.commitPending(snippetId);
  }

  get currentSnippetId() {
    return this.context.stores.currentLocation.get().snippetId;
  }
}
