import { some, forEach } from 'lodash';
import React from 'react';

import connect from 'components/lib/connect';
import FeatureNotification from './feature_notification';
import FeatureNotificationTypes from '../../../domain/models/agent_notification/feature_notification_types';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';

const crossChannelConversationsClassroom = {
  feature: FeatureNotificationTypes.CROSS_CHANNEL_CONVERSATIONS_CLASSROOM,
  roleFlags: ['teamManagement'],
  key: 'cross-channel-conversations-classroom',
  text: 'Cross-Channel Conversations Classroom',
  subtext:
    'Discover what cross-channel conversations look like and the factors that allow Gladly to enable heroes to provide support in the channels customers prefer.',
  url: 'https://gladly.zoom.us/meeting/register/tZ0rfuugrDwsEtU8v-DsKd40ytO-x7GgpeOS',
  urlText: 'Register',
};

const CURRENT_FEATURES = [crossChannelConversationsClassroom];

export function FeatureNotificationList({ agentPreferences, organization }) {
  const isFeatureEnabled = useIsFeatureEnabled();

  let featureNotifications = [];
  forEach(CURRENT_FEATURES, featureProps => {
    if (shouldShowNotification(featureProps, agentPreferences, isFeatureEnabled, organization)) {
      featureNotifications.push(<FeatureNotification {...featureProps} />);
    }
  });

  if (!featureNotifications.length) {
    return null;
  }

  return <React.Fragment>{featureNotifications}</React.Fragment>;
}

const FeatureNotificationListContainer = connect(mapStateToProps)(FeatureNotificationList);

function mapStateToProps({ getProvider }) {
  const agentPreferences = getProvider('agentPreferences').get();
  const organization = getProvider('organization').get();

  return {
    agentPreferences,
    organization,
  };
}

export function useHasFeaturesToShow(agentPreferences) {
  const isFeatureEnabled = useIsFeatureEnabled();
  return some(CURRENT_FEATURES, featureProps => {
    return shouldShowNotification(featureProps, agentPreferences, isFeatureEnabled);
  });
}

function shouldShowNotification(featureProps, agentPreferences, isFeatureEnabled, organization) {
  const roleRestricted = featureProps.roleFlags && featureProps.roleFlags.length;
  const restrictedAndHasRole = some(featureProps.roleFlags, roleFlag => isFeatureEnabled(roleFlag));
  const flagRestricted = featureProps.flag;

  const orgRestricted = !!featureProps.orgIds;
  const belongsToOrg = some(featureProps.orgIds, orgId => orgId === organization.id);

  return (
    (!orgRestricted || belongsToOrg) &&
    (!roleRestricted || restrictedAndHasRole) &&
    (!flagRestricted || isFeatureEnabled(featureProps.flag)) &&
    !agentPreferences.hasSeenFeatureNotification(featureProps.feature)
  );
}

export default FeatureNotificationListContainer;
