import { getAllLinkedAnswerIds } from 'models/answers/snippet_helpers';

export default class FetchAnswerLinks {
  constructor(context) {
    this.context = context;
  }

  run({ answer }) {
    const linkedAnswerIds = getAllLinkedAnswerIds(answer);

    if (linkedAnswerIds.length) {
      const answerPanel = this.context.stores.answerPanel.get();
      answerPanel.addLoadingAnswerIds(linkedAnswerIds);
      this.context.stores.answerPanel.set(answerPanel);
      this.context.gateways.snippet.fetch({ ids: linkedAnswerIds });
    }
  }
}
