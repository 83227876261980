import _ from 'lodash';
import * as amplitude from '@amplitude/analytics-browser';

export default class AmplitudeAdapter {
  constructor(amplitudeApiKey) {
    this.isEnabled = false;
    this.amplitudeApiKey = amplitudeApiKey;
  }

  init() {
    // Documentation: https://www.docs.developers.amplitude.com/data/sdks/browser-2/#configuration
    this._init(this.amplitudeApiKey, {
      // This ensures we do not track events until explicitly told to do so,
      // e.g. after login
      optOut: true,
      defaultTracking: false,
      // logLevel: 4, uncomment for debugging
    });
  }

  enable() {
    if (!this.isEnabled) {
      this.isEnabled = true;
      this._setOptOut(false);
    }
  }

  disable() {
    if (this.isEnabled) {
      this._reset();
      this._setOptOut(true);
      this.isEnabled = false;
    }
  }

  track(eventName, eventProperties) {
    if (!this.isEnabled) {
      return;
    }
    this._track(eventName, eventProperties);
  }

  setUser(user) {
    if (!this.isEnabled || !user || !user.id) {
      return;
    }
    this._setUserId(user.id);
    let userProperties = _.omit(user, 'id');
    if (!_.isEmpty(userProperties)) {
      this.updateUser(userProperties);
    }
  }

  updateUser(properties) {
    if (!this.isEnabled) {
      return;
    }
    let userProperties = _(properties)
      .omit('id')
      .omitBy(_.isUndefined)
      .omitBy(_.isNull)
      .value();
    if (_.isEmpty(userProperties)) {
      return;
    }
    const identifyEvent = new amplitude.Identify();
    _.forEach(userProperties, (value, key) => {
      identifyEvent.set(key, value);
    });
    this._identify(identifyEvent);
  }

  _init(apiKey, options) {
    amplitude.init(apiKey, options);
  }

  _reset() {
    amplitude.reset();
  }

  _setOptOut(optOut) {
    amplitude.setOptOut(optOut);
  }

  addPlugin(plugin) {
    amplitude.add(plugin);
  }

  _track(eventName, eventProperties) {
    amplitude.track(eventName, eventProperties);
  }

  _setUserId(userId) {
    amplitude.setUserId(userId);
  }

  _identify(identifyEvent) {
    amplitude.identify(identifyEvent);
  }
}
