import _ from 'lodash';

import MqttGateway from './lib/mqtt_gateway';

const snippetRequestCallbacks = {};

export default class SnippetGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'snippets/+';
  }

  add(snippetDto) {
    return this._add([], snippetDto);
  }

  fetch(payload, requestCallbacks) {
    const { correlationId } = super._fetch([], payload);
    if (requestCallbacks) {
      SnippetGateway.addRequest(correlationId, requestCallbacks);
    }
    return { correlationId };
  }

  onFetchResponse(fetchResultDto, topic, parsedTopicParams, correlationId) {
    const request = SnippetGateway.getRequest(correlationId);
    if (request) {
      request.onSuccess(fetchResultDto);
      SnippetGateway.removeRequest(correlationId);
    } else {
      this.notifyObservers('handleRequestedEntities', fetchResultDto);
    }
  }

  onFetchError(errorDto, topic, parsedTopicParams, correlationId) {
    const request = SnippetGateway.getRequest(correlationId);
    if (request) {
      request.onError(errorDto);
      SnippetGateway.removeRequest(correlationId);
    } else {
      this.notifyObservers('handleRequestError', errorDto);
    }
  }

  static getRequest(correlationId) {
    return snippetRequestCallbacks[correlationId];
  }

  static addRequest(correlationId, callback) {
    snippetRequestCallbacks[correlationId] = callback;
  }

  static removeRequest(correlationId) {
    delete snippetRequestCallbacks[correlationId];
  }

  request() {
    return super._subscribeAndFetch();
  }

  update(snippetId, snippetAttrs) {
    return this._update([snippetId], snippetAttrs);
  }

  remove(snippetId) {
    return this._delete([snippetId]);
  }

  onReceive(entityDto, topic, parsedTopicParams) {
    this.notifyObservers('handleEntity', entityDto);
  }

  onDelete(topic, parsedTopicParams) {
    this.notifyObservers('handleDelete', _.last(parsedTopicParams));
  }

  onAddSuccess(payload, topic, parsedTopicParams) {
    this.notifyObservers('handleAddSuccess', payload);
  }

  onAddErrors(errorDtos, topic, parsedTopicParams) {
    this.notifyObservers('handleAddErrors', errorDtos);
  }

  onUpdateSuccess(payload, topic, parsedTopicParams) {
    this.notifyObservers('handleUpdateSuccess', _.last(parsedTopicParams), payload);
  }

  onUpdateErrors(errorDtos, topic, parsedTopicParams) {
    this.notifyObservers('handleUpdateErrors', _.last(parsedTopicParams), errorDtos);
  }

  onDeleteSuccess(payload, topic, parsedTopicParams) {
    this.notifyObservers('handleDeleteSuccess', _.last(parsedTopicParams));
  }

  onDeleteErrors(errorDtos, topic, parsedTopicParams) {
    this.notifyObservers('handleDeleteErrors', _.last(parsedTopicParams), errorDtos);
  }
}
