import _ from 'lodash';

import DtoConverter from 'scripts/application/dto_converters/snippet_converter';
import { getAllLinkedAnswerIds } from 'models/answers/snippet_helpers';
import qconsole from 'scripts/lib/qconsole';

export default class SnippetCompositionGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.snippetComposition;
  }

  handleRequestedEntities(snippetDtos) {
    let snippetDto = _.first(snippetDtos);
    let snippet = snippetDto && DtoConverter.fromDto(snippetDto);
    let snippetComposition = this.store.get();
    if (snippetComposition && snippetComposition.id === (snippet && snippet.id)) {
      this.store.set(snippet);
      this.context.stores.snippets.addOrReplace(snippet);
      this.store.clearErrors();
      this.store.resetLoading();

      // to prevent infinite looping
      let snippetLinkIds = _.filter(getAllLinkedAnswerIds(snippet), id => {
        const currentSnippetId = snippet && snippet.id;
        return id !== currentSnippetId;
      });

      if (snippetLinkIds.length) {
        this.context.stores.snippetLinks.setLoading();
        this.context.gateways.snippet.fetch({ ids: snippetLinkIds });
      }
    }
  }

  handleRequestError(errorDto) {
    qconsole.log('Failed to fetch snippets', errorDto);
  }
}
