import _ from 'lodash';

import FakeBackend from './fake_backend';
import { getDatabase } from './fake_backend/database';

export const DEMO_TOPICS = [
  'agent-assistance',
  'company-profile',
  'customer-assignee',
  'reports',
  'suggestions',
  'transactions',
];

export default class DemoPubsubClient {
  constructor(mqttClient, localPubsub, subscriberShimFactories, publisherShims) {
    this._mqttClient = mqttClient;
    this._localPubsub = localPubsub;
    this._subscriberShimFactories = subscriberShimFactories;
    this._publisherShims = publisherShims;
    this._subscriberShims = new Map();
    this.fakeBackend = new FakeBackend(this._localPubsub, getDatabase);
  }

  publish(topic, message) {
    for (let i = 0; i < this._publisherShims.length; i++) {
      let publisherShimFactory = this._publisherShims[i];
      let matches = publisherShimFactory.pattern.doesMatch(topic);

      if (matches) {
        this._localPubsub.publish.bind(this._localPubsub);
        const published = publisherShimFactory.shim(topic, message, this._localPubsub.publish);
        if (published) {
          return;
        }
      }
    }

    if (this._isDemoTopic(topic)) {
      this._localPubsub.publish(topic, message);
    } else {
      this._mqttClient.publish(topic, message);
    }
  }

  subscribe(topicPattern, onMessage, onAck) {
    for (let i = 0; i < this._subscriberShimFactories.length; i++) {
      let shimFactory = this._subscriberShimFactories[i];
      let matches = shimFactory.pattern.doesMatch(topicPattern);

      if (matches) {
        let shim = this._subscriberShims.get(onMessage) || shimFactory.shim(onMessage, this.fakeBackend);
        this._subscriberShims.set(onMessage, shim);
        onMessage = shim;
        break;
      }
    }
    if (this._isDemoTopic(topicPattern)) {
      this._localPubsub.subscribe(topicPattern, onMessage, onAck);
    } else {
      this._mqttClient.subscribe(topicPattern, onMessage, onAck);
    }
  }

  unsubscribe(topic, onReceive) {
    let callback = this._subscriberShims[onReceive] || onReceive;
    this._localPubsub.unsubscribe(topic, callback);
    this._mqttClient.unsubscribe(topic, callback);
    this._subscriberShims.delete(onReceive);
  }

  on(event, listener) {
    this._mqttClient.on(event, listener);
  }

  removeListener(event, listener) {
    this._mqttClient.removeListener(event, listener);
  }

  connect(username, orgId) {
    this._mqttClient.connect(username, orgId);
  }

  configureMqttServerV2(isMqttServerV2) {
    this._mqttClient.configureMqttServerV2(isMqttServerV2);
  }

  multiplyDefaultMqttKeepAlive(multiplier) {
    this._mqttClient.multiplyDefaultMqttKeepAlive(multiplier);
  }

  reset() {
    this._mqttClient.reset();
  }

  activateDemoClient() {
    this.fakeBackend.activate();
  }

  _isDemoTopic(topic) {
    return _.some(DEMO_TOPICS, demoTopic => topic.includes(demoTopic));
  }

  close() {
    this._localPubsub.close();
  }
}
